import { Box, Button, FormHelperText } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { UserInfos, UserInfosUpdate } from 'shared/schema/User/User';
import AppError from 'src/components/_app/AppError/AppError';
import { useForm } from 'src/hooks/useForm';
import { useUpdateUserInfosMutation } from 'src/services/user.service';

interface Props {
  userId: string;
  userInfos: UserInfos;
  onSubmit?: () => void;
}

const AccountInfosForm = ({ userId, userInfos, onSubmit }: Props) => {
  const [firstName, setFirstName] = useState(userInfos.firstName);
  const [lastName, setLastName] = useState(userInfos.lastName);

  const [updateUserInfos, { isError: isUpdateError }] =
    useUpdateUserInfosMutation();

  const { message, validate, messageType } = useForm(UserInfosUpdate, {
    firstName,
    lastName,
  });

  const submit = async () => {
    await validate(async () => {
      await updateUserInfos({
        userId,
        userInfosUpdate: {
          firstName,
          lastName,
        },
      })
        .unwrap()
        .then(() => {
          onSubmit?.();
        });
    });
  };

  return (
    <Box component="form">
      <TextField
        value={userInfos?.email}
        disabled
        autoFocus
        label="Votre email"
        type="email"
        fullWidth
        margin="normal"
        variant="standard"
        required
      />
      <TextField
        value={firstName}
        onChange={(event) => setFirstName(event.target.value)}
        label={`Votre prénom`}
        type="text"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('firstName') === 'error'}
        required
      />
      <FormHelperText error>{message('firstName')}</FormHelperText>
      <TextField
        value={lastName}
        onChange={(event) => setLastName(event.target.value)}
        label={`Votre nom`}
        type="text"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('lastName') === 'error'}
        required
      />
      <FormHelperText error>{message('lastName')}</FormHelperText>
      <AppError open={isUpdateError} />
      <Button
        variant="contained"
        onClick={submit}
        sx={{ marginY: '2rem' }}
        fullWidth
      >
        Enregistrer
      </Button>
    </Box>
  );
};

export default AccountInfosForm;
