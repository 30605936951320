import { Box, Grid2 as Grid, Skeleton, Typography } from '@mui/material';
import { format } from 'date-fns';
import {
  OpportunitySource,
  OpportunitySourceLabels,
} from 'shared/schema/Opportunity/OpportunitySource';
import { TeamOpportunity } from 'shared/schema/TeamOpportunity/TeamOpportunity';
import OpportunityManagement from 'src/components/OpportunityDetails/OpportunityManagement';
import OpportunityOwner from 'src/components/OpportunityDetails/OpportunityOwner';
import './OpportunityDetails.scss';
interface Props {
  opportunity: TeamOpportunity;
}

const NotSpecified = () => (
  <Typography variant="caption">
    <i>Non renseigné</i>
  </Typography>
);

const OpportunityDetails = ({ opportunity }: Props) => {
  return (
    <Grid container spacing={2} className="opportunity-details">
      <Grid size={{ xs: 12, md: 8 }}>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }} sx={{ paddingTop: 1 }}>
            {opportunity.picture ? (
              <Box
                component="img"
                src={opportunity.picture}
                alt={opportunity.title}
                sx={{
                  width: '100%',
                  maxHeight: '200px',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Skeleton
                variant="rectangular"
                sx={{ width: '100%', height: 200 }}
              />
            )}
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <OpportunityOwner opportunity={opportunity} />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            {opportunity.publishedAt && (
              <Typography variant="caption" color="textSecondary">
                Publié le :{' '}
                {format(
                  new Date(opportunity.publishedAt),
                  'dd/MM/yyyy à HH:mm'
                )}
                sur{' '}
                {
                  OpportunitySourceLabels[
                    opportunity.source as OpportunitySource
                  ]
                }
              </Typography>
            )}
            {opportunity.price && (
              <Typography color="primary" sx={{ fontWeight: 'bold' }}>
                {opportunity.price} €
              </Typography>
            )}
            <Typography id="modal-description" sx={{ mt: 2 }}>
              <strong>Marque :</strong> {opportunity.brand || <NotSpecified />}
            </Typography>
            <Typography>
              <strong>Modèle :</strong> {opportunity.model || <NotSpecified />}
            </Typography>
            <Typography>
              <strong>Année :</strong> {opportunity.year || <NotSpecified />}
            </Typography>
            <Typography>
              <strong>Kilomètres :</strong>{' '}
              {opportunity.kilometers ? (
                `${opportunity.kilometers} kms`
              ) : (
                <NotSpecified />
              )}
            </Typography>
            <Typography>
              <strong>Carburant :</strong>{' '}
              {opportunity.fuel ? opportunity.fuel : <NotSpecified />}
            </Typography>
            <Typography>
              <strong>Boîte de vitesse :</strong>{' '}
              {opportunity.gearbox ? opportunity.gearbox : <NotSpecified />}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <strong>Description</strong>{' '}
            <Typography
              sx={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
              }}
            >
              {opportunity.description || <NotSpecified />}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        size={{ xs: 12, md: 4 }}
        sx={{
          paddingTop: 1,
          paddingLeft: 1,
          borderLeft: 1,
          borderColor: 'grey.300',
        }}
      >
        <OpportunityManagement teamOpportunity={opportunity} />
      </Grid>
    </Grid>
  );
};

export default OpportunityDetails;
