import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Link as LinkIcon,
  OpenInNew,
  Person,
  Phone,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { format } from 'date-fns';
import { useState } from 'react';
import { TeamOpportunity } from 'shared/schema/TeamOpportunity/TeamOpportunity';
import { Fuel, FuelLabel } from 'shared/schema/Vehicle/Fuel';
import { isDefined } from 'shared/utils/utils';
import OpportunityDetails from 'src/components/OpportunityDetails/OpportunityDetails';
import AppCloseButton from 'src/components/_app/AppCloseButton/AppCloseButton';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { formatPhoneNumber } from 'src/utils/stringUtils';
export type Props = {
  opportunity: TeamOpportunity;
};

const OpportunityCard = ({ opportunity }: Props) => {
  const { hasTeam, getTeamUser } = useAuthentication();
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: opportunity.id,
  });

  const [editCardDialogOpen, setEditCardDialogOpen] = useState(false);

  return (
    <>
      <Card
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        onClick={() => setEditCardDialogOpen(true)}
        onDoubleClick={() => setEditCardDialogOpen(true)}
        sx={{
          maxWidth: 200,
          boxShadow: 2,
          borderRadius: 2,
          overflow: 'hidden',
          transform: CSS.Transform.toString(transform),
          cursor: 'pointer',
          ':hover': {
            boxShadow: 4,
          },
          ':active': {
            boxShadow: '0 0 0 2px rgba(255, 0, 0, 0.5)',
          },
        }}
        id={opportunity.id}
      >
        {opportunity.picture && (
          <CardMedia
            component="img"
            image={opportunity.picture}
            alt={opportunity.title}
            draggable={false}
            sx={{
              aspectRatio: '3/2',
            }}
          />
        )}

        <CardContent sx={{ padding: 1, ':last-child': { paddingBottom: 1 } }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontSize: 14, fontWeight: 'bold', lineHeight: 1.2 }}
          >
            {opportunity.title}
          </Typography>

          <Divider sx={{ marginY: 1 }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body2"
              color="primary"
              sx={{ fontWeight: 'bold', flexGrow: 1 }}
            >
              {opportunity.price} €
            </Typography>
            {opportunity.ownerPhone && (
              <Chip
                label={
                  <Link
                    href={`tel:${opportunity.ownerPhone}`}
                    onClick={(e) => e.stopPropagation()}
                    sx={{ color: 'inherit', textDecoration: 'none' }}
                  >
                    {formatPhoneNumber(opportunity.ownerPhone)}
                  </Link>
                }
                size="small"
                color="info"
                icon={<Phone />}
                variant="outlined"
              />
            )}
          </Box>

          <Typography
            variant="body2"
            sx={{ fontSize: 12, color: 'text.secondary' }}
          >
            {[opportunity.model, opportunity.year]
              .filter(isDefined)
              .join(' - ')}
          </Typography>

          <Typography
            variant="body2"
            sx={{ fontSize: 12, color: 'text.secondary' }}
          >
            {[
              opportunity.kilometers
                ? `${opportunity.kilometers} kms`
                : undefined,
              opportunity.fuel
                ? FuelLabel[opportunity.fuel as Fuel]
                : undefined,
            ]
              .filter(isDefined)
              .join(' - ')}
          </Typography>

          {opportunity.publishedAt && (
            <Typography
              variant="caption"
              sx={{ fontSize: 10, color: 'text.secondary', marginTop: 1 }}
            >
              Publié le :{' '}
              {format(new Date(opportunity.publishedAt), 'dd/MM/yyyy')}
            </Typography>
          )}
          {hasTeam && (
            <Box>
              <Chip
                sx={{ marginTop: 1 }}
                label={
                  getTeamUser(opportunity.managedBy)
                    ? `${getTeamUser(opportunity.managedBy)?.firstName} ${
                        getTeamUser(opportunity.managedBy)?.lastName
                      }`
                    : 'Non attribué'
                }
                size="small"
                color={opportunity.managedBy ? 'primary' : 'default'}
                icon={<Person />}
              />
            </Box>
          )}
        </CardContent>
      </Card>
      <Dialog
        maxWidth="md"
        fullWidth
        disableScrollLock
        open={editCardDialogOpen}
        disableEscapeKeyDown={false}
        onClose={() => setEditCardDialogOpen(false)}
      >
        <DialogTitle fontWeight="bold" sx={{ paddingBottom: 1 }}>
          {opportunity.title}
          {opportunity.link && (
            <>
              <IconButton
                onClick={() => window.open(opportunity.link as string)}
                color="primary"
                sx={{ marginLeft: 1 }}
              >
                <OpenInNew />
              </IconButton>
              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(opportunity.link as string)
                }
                color="primary"
              >
                <LinkIcon />
              </IconButton>
            </>
          )}
          <AppCloseButton onClose={() => setEditCardDialogOpen(false)} />
        </DialogTitle>
        <DialogContent>
          <OpportunityDetails opportunity={opportunity} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OpportunityCard;
