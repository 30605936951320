import {
  Alert,
  Box,
  Container,
  FormHelperText,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignIn } from 'shared/schema/SignIn';
import AccountForgotPasswordButton from 'src/components/Account/AccountForgotPassword/AccountForgotPasswordButton';
import { useForm } from 'src/hooks/useForm';
import { useAppDispatch } from 'src/hooks/useStore';
import { useSignInMutation } from 'src/services/account.service';
import authSlice from 'src/store/reducers/authSlice';

interface Props {
  onNavigate?: () => void;
  onConnect?: () => void;
  showSignup?: boolean;
}

const AccountSigninForm = ({
  onNavigate,
  onConnect,
  showSignup = false,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [signIn, { isError: isSignInError }] = useSignInMutation();

  const { message, validate, messageType } = useForm(SignIn, {
    email,
    password,
  });

  const submit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    await validate(async () => {
      await signIn({
        email,
        password,
      })
        .unwrap()
        .then((authUser) => {
          dispatch(authSlice.actions.signinUser({ authUser }));
          onConnect?.();
        });
    });
  };

  return (
    <Container maxWidth="sm">
      <Box component="form">
        <TextField
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          autoFocus
          label="Votre email"
          type="email"
          fullWidth
          margin="normal"
          variant="standard"
          error={messageType('email') === 'error'}
          required
        />
        <FormHelperText error>{message('email')}</FormHelperText>
        <TextField
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          label="Mot de passe"
          type="password"
          fullWidth
          margin="normal"
          variant="standard"
          error={messageType('password') === 'error'}
          required
        />
        <FormHelperText error>{message('password')}</FormHelperText>
        {isSignInError && (
          <Alert severity="error">
            Identifiants non valide, veuillez réessayer.
          </Alert>
        )}
        <Button
          variant="contained"
          onClick={submit}
          sx={{ marginTop: '2rem' }}
          fullWidth
        >
          Me connecter
        </Button>
        <AccountForgotPasswordButton onNavigate={onNavigate} />
        {showSignup && (
          <>
            <Divider sx={{ marginY: '1rem' }}>OU</Divider>
            <Typography variant="h6">
              Vous n'avez pas encore de compte ?
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                navigate('/inscription');
                onNavigate?.();
              }}
              fullWidth
              sx={{ marginY: '1rem' }}
            >
              Inscrivez-vous
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default AccountSigninForm;
