import { Check, Send } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridValidRowModel,
  ValueOptions,
} from '@mui/x-data-grid';
import _ from 'lodash';
import { Team } from 'shared/schema/Team/Team';
import { User } from 'shared/schema/User/User';
import {
  UserRole,
  UserRoleLabels,
  UserRoleList,
} from 'shared/schema/User/UserRole';
import AddUserButton from 'src/components/UserList/AddUserButton';
import AppSnackbar from 'src/components/_app/AppSnackbar/AppSnackbar';
import { useDataGrid } from 'src/hooks/useDataGrid';
import { useSendPasswordLinkMutation } from 'src/services/account.service';
import { useFindTeamsQuery } from 'src/services/team.service';
import {
  useFindUsersQuery,
  useUpdateUserMutation,
} from 'src/services/user.service';

const UserList = () => {
  const { apiRef, initialStateFilter } = useDataGrid('user');

  const initialState = {
    ...initialStateFilter,
    sorting: {
      sortModel: [{ field: 'department', sort: 'asc' as GridSortDirection }],
    },
  };

  const [updateUser, { isSuccess: isUpdateSuccess }] = useUpdateUserMutation();
  const [sendPasswordLink, { isSuccess: isSendEmailSuccess }] =
    useSendPasswordLinkMutation();

  const { data } = useFindUsersQuery({});
  const users = data ?? [];

  const { data: teams } = useFindTeamsQuery();

  const processRowUpdate = async (
    updatedRow: GridValidRowModel,
    originalRow: GridValidRowModel
  ) => {
    if (!_.isEqual(updatedRow, originalRow)) {
      await updateUser(updatedRow as User);
    }
    return updatedRow;
  };

  const sendLink = async (email: string) => {
    await sendPasswordLink(email);
  };

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Prénom',
      width: 200,
      editable: true,
    },
    {
      field: 'lastName',
      headerName: 'Nom',
      width: 200,
      editable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      editable: true,
    },
    {
      field: 'teamId',
      headerName: 'Equipe',
      width: 200,
      editable: false,
      type: 'singleSelect',
      valueOptions: teams,
      getOptionLabel: (option: ValueOptions) => (option as Team).name,
      getOptionValue: (option: ValueOptions) => (option as Team).id,
    },
    {
      field: 'role',
      headerName: 'Rôle',
      width: 150,
      editable: true,
      type: 'singleSelect',
      valueOptions: UserRoleList.filter((role) => role !== 'Admin'),
      getOptionLabel: (option) => UserRoleLabels[option as UserRole],
    },
    {
      field: 'activatedAt',
      headerName: 'Compte activé ?',
      width: 150,
      editable: false,
      type: 'boolean',
      renderCell: (params) => {
        return params.value ? <Check color="primary" /> : <></>;
      },
    },
    {
      field: 'accountLink',
      headerName: 'Mot de passe',
      type: 'actions',
      width: 150,
      getActions: ({ row }: GridRowParams<User>) => [
        <IconButton
          title="Envoyer un mot de passe par mail"
          onClick={() => {
            sendLink(row.email);
          }}
        >
          <Send />
        </IconButton>,
      ],
    },
  ];

  const toolbar = () => (
    <GridToolbarContainer>
      <AddUserButton />
      <Box sx={{ flexGrow: 1 }}></Box>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );

  return (
    <>
      <AppSnackbar
        isOpen={isUpdateSuccess}
        severity="success"
        message="Modification enregistrée"
      />
      <AppSnackbar
        isOpen={isSendEmailSuccess}
        severity="success"
        message="Un mail a été envoyé à l'utilisateur pour qu'il choisisse son mot de passe"
      />
      <Box height="calc(100vh - 250px)">
        <DataGrid
          apiRef={apiRef}
          initialState={initialState}
          rows={users}
          columns={columns}
          disableColumnSelector
          disableRowSelectionOnClick
          processRowUpdate={processRowUpdate}
          hideFooter
          slots={{ toolbar }}
        />
      </Box>
    </>
  );
};

export default UserList;
