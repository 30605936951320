import { Box, Button, FormHelperText } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { Password } from 'shared/schema/SignIn';
import AppError from 'src/components/_app/AppError/AppError';
import { useForm } from 'src/hooks/useForm';
import { useResetPasswordMutation } from 'src/services/account.service';
import { z } from 'zod';
interface Props {
  tokenId: string;
}

const AccountResetPasswordForm = ({ tokenId }: Props) => {
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const [resetPassword, { isError: isUpdateError }] = useResetPasswordMutation({
    fixedCacheKey: 'shared-reset-password',
  });

  const Form = z
    .object({
      newPassword: Password,
      newPasswordConfirm: Password,
    })
    .refine((data) => data.newPassword === data.newPasswordConfirm, {
      path: ['newPasswordConfirm'],
      message: 'Les mots de passe ne correspondent pas',
    });

  const { message, validate, messageType } = useForm(Form, {
    newPassword,
    newPasswordConfirm,
  });

  const submit = async () => {
    await validate(async () => {
      await resetPassword({
        tokenId,
        newPassword,
      });
    });
  };

  return (
    <Box component="form">
      <TextField
        value={newPassword}
        onChange={(event) => setNewPassword(event.target.value)}
        autoFocus
        label="Nouveau mot de passe"
        type="password"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('newPassword') === 'error'}
        required
      />
      <FormHelperText error>{message('newPassword')}</FormHelperText>
      <TextField
        value={newPasswordConfirm}
        onChange={(event) => setNewPasswordConfirm(event.target.value)}
        label="Confirmation du nouveau mot de passe"
        type="password"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('newPasswordConfirm') === 'error'}
        required
      />
      <FormHelperText error>{message('newPasswordConfirm')}</FormHelperText>
      <AppError open={isUpdateError} />
      <Button
        variant="contained"
        onClick={submit}
        sx={{ marginY: '2rem' }}
        fullWidth
      >
        Enregistrer
      </Button>
    </Box>
  );
};

export default AccountResetPasswordForm;
