import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';
import { UserRole } from 'shared/schema/User/UserRole';
import { useAppSelector } from 'src/hooks/useStore';
import { useGetTeamQuery } from 'src/services/team.service';
import { useGetUserInfosQuery } from 'src/services/user.service';
import OpportunitiesView from 'src/views/OpportunitiesView/OpportunitiesView';
import UsersView from 'src/views/UsersView/UsersView';

export const useAuthentication = () => {
  const { authUser } = useAppSelector((state) => state.auth);

  const { data: userInfos } = useGetUserInfosQuery(
    authUser?.userId ?? skipToken
  );

  const { data: team } = useGetTeamQuery(authUser?.teamId ?? skipToken);

  const hasTeam = useMemo(() => team?.kind === 'Multiple', [team]);

  const getTeamUser = useMemo(
    () => (userId?: string | null) =>
      team?.users.find((user) => user.id === userId),
    [team]
  );

  const isAuthenticated = useMemo(() => !!authUser?.userId, [authUser]);

  const hasRole = useMemo(
    () => (userRole: UserRole) =>
      isAuthenticated && userInfos?.role === userRole,
    [userInfos, isAuthenticated]
  );

  const isAdmin = useMemo(() => hasRole('Admin'), [hasRole]);

  const isManager = useMemo(() => hasRole('Manager'), [hasRole]);

  const availableRoutes = useMemo(() => {
    const opportunitiesRoute = {
      path: '/annonces',
      label: 'Annonces',
      key: 'opportunities_route',
      component: OpportunitiesView,
    };
    // const purchasersRoute = {
    //   path: '/acheteurs',
    //   label: 'Acheteurs',
    //   key: 'purchasers_route',
    //   component: PurchasersView,
    // };
    const usersRoute = {
      path: '/utilisateurs',
      label: 'Utilisateurs',
      key: 'users_route',
      component: UsersView,
    };

    if (isAdmin) {
      return [opportunitiesRoute, usersRoute];
    }

    if (isManager) {
      return [opportunitiesRoute];
    }

    return [opportunitiesRoute];
  }, [isManager, isAdmin]);

  return {
    userId: authUser?.userId,
    userInfos,
    isAuthenticated,
    hasRole,
    isAdmin,
    isManager,
    availableRoutes,
    team,
    hasTeam,
    getTeamUser,
  };
};
