import { z } from 'zod';
import { OpportunitySource } from '../Opportunity/OpportunitySource';

export const DraftOwner = z.object({
  id: z.string().uuid(),
  email: z.string().nullish(),
  name: z.string().nullish(),
  phone: z.string().nullish(),
  source: OpportunitySource.nullish(),
  sourceId: z.coerce.string().nullish(),
});

export const Owner = z.object({
  ...DraftOwner.shape,
  lastUpdatedAt: z.coerce.date(),
});

export type Owner = z.infer<typeof Owner>;
export type DraftOwner = z.infer<typeof DraftOwner>;
