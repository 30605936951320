import fp from 'lodash';
import {
  Team,
  TeamToCreate,
  TeamUpdate,
  TeamWithUsers,
} from 'shared/schema/Team/Team';
import { api } from 'src/services/api.service';

export const teamApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTeam: builder.query<TeamWithUsers, string>({
      query: (teamId) => `teams/${teamId}`,
      transformResponse: (response: any) => TeamWithUsers.parse(response),
      providesTags: (result, error, teamId) => [{ type: 'Team', id: teamId }],
    }),
    updateTeam: builder.mutation<
      void,
      { teamId: string; teamUpdate: TeamUpdate }
    >({
      query: ({ teamId, teamUpdate }) => ({
        url: `teams/${teamId}`,
        method: 'PUT',
        body: teamUpdate,
      }),
      invalidatesTags: (result, error, { teamId }) => [
        { type: 'Team', id: teamId },
      ],
    }),
    findTeams: builder.query<TeamWithUsers[], void>({
      query: () => 'teams',
      transformResponse: (response: any[]) =>
        response.map((_) => TeamWithUsers.parse(fp.omitBy(_, fp.isNil))),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Team' as const,
                id,
              })),
              { type: 'Team', id: 'LIST' },
            ]
          : [{ type: 'Team', id: 'LIST' }],
    }),
    createTeam: builder.mutation<Team, TeamToCreate>({
      query: (body) => ({
        url: 'teams',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Team', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetTeamQuery,
  useUpdateTeamMutation,
  useFindTeamsQuery,
  useCreateTeamMutation,
} = teamApi;
