import { z } from 'zod';
import { Opportunity } from '../Opportunity/Opportunity';
import { OpportunityStatus } from '../Opportunity/OpportunityStatus';

export const TeamOpportunity = z.object({
  ...Opportunity.shape,
  status: OpportunityStatus,
  order: z.number().int(),
  managedBy: z.string().uuid().nullish(),
  reminderDate: z.coerce.date().nullish(),
  ownerPhone: z.string().nullish(),
});
export const TeamOpportunityUpdate = z.object({
  status: OpportunityStatus,
  order: z.number().int(),
  managedBy: z.string().uuid().nullish(),
  reminderDate: z.coerce.date().nullish(),
});
export type TeamOpportunityUpdate = z.infer<typeof TeamOpportunityUpdate>;
export type TeamOpportunity = z.infer<typeof TeamOpportunity>;
