import { FindUserOptions } from 'shared/schema/User/FindUserOptions';
import {
  User,
  UserInfos,
  UserInfosUpdate,
  UserPasswordUpdate,
  UserToCreate,
} from 'shared/schema/User/User';
import { api } from 'src/services/api.service';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findUsers: builder.query<User[], FindUserOptions>({
      query: (findOptions) => ({
        url: 'users',
        params: findOptions,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'User' as const,
                id,
              })),
              { type: 'User', id: 'LIST' },
            ]
          : [{ type: 'User', id: 'LIST' }],
    }),
    createUser: builder.mutation<User, UserToCreate>({
      query: (body) => ({
        url: 'users',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),
    updateUser: builder.mutation<User, User>({
      query: ({ id, ...body }) => ({
        url: `users/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),
    getUserInfos: builder.query<UserInfos, string>({
      query: (userId) => `users/${userId}/infos`,
      transformResponse: (response: any) => UserInfos.parse(response),
      providesTags: () => ['User'],
    }),
    updateUserInfos: builder.mutation<
      void,
      { userId: string; userInfosUpdate: UserInfosUpdate }
    >({
      query: ({ userId, userInfosUpdate }) => ({
        url: `users/${userId}/infos`,
        method: 'PUT',
        body: userInfosUpdate,
      }),
      invalidatesTags: (result, error, { userId }) => [
        { type: 'UserInfos', id: userId },
      ],
    }),
    updateUserPassword: builder.mutation<
      void,
      { userId: string; userPasswordUpdate: UserPasswordUpdate }
    >({
      query: ({ userId, userPasswordUpdate }) => ({
        url: `users/${userId}/password`,
        method: 'PUT',
        body: userPasswordUpdate,
      }),
    }),
  }),
});

export const {
  useGetUserInfosQuery,
  useUpdateUserInfosMutation,
  useUpdateUserPasswordMutation,
  useFindUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
} = userApi;
