import { useDroppable } from '@dnd-kit/core';
import { Box, Paper, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import React from 'react';
import { Opportunity } from 'shared/schema/Opportunity/Opportunity';
import {
  OpportunityStatus,
  OpportunityStatusLabel,
} from 'shared/schema/Opportunity/OpportunityStatus';
import { TeamOpportunity } from 'shared/schema/TeamOpportunity/TeamOpportunity';
import { theme } from 'src/theme';
import { z } from 'zod';
import OpportunityCard from './OpportunityCard';

const VirtualOpportunity = TeamOpportunity.extend({
  isVirtual: z.boolean().default(true),
});

type VirtualOpportunity = z.infer<typeof VirtualOpportunity>;

export const isVirtualOpportunity = (
  opportunity: Opportunity | VirtualOpportunity
): opportunity is VirtualOpportunity => {
  return (opportunity as VirtualOpportunity).isVirtual;
};

export type OpportunityColumnProps = {
  status: OpportunityStatus;
  opportunities: (TeamOpportunity | VirtualOpportunity)[];
};

const OpportunityColumn = ({
  status,
  opportunities,
}: OpportunityColumnProps) => {
  const { setNodeRef } = useDroppable({ id: status });
  const [newCardDialogOpen, setNewCardDialogOpen] = React.useState(false);
  return (
    <Paper
      ref={setNodeRef}
      sx={{
        minWidth: '200px',
        padding: 1,
        margin: 1,
        backgroundColor: '#f9f9f9',
      }}
    >
      <Typography sx={{ fontWeight: 'bold' }}>
        {OpportunityStatusLabel[status]} (
        {opportunities.filter((o) => !isVirtualOpportunity(o)).length})
      </Typography>
      <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
      {/*<Button startIcon={<Add />} onClick={() => setNewCardDialogOpen(true)}>*/}
      {/*  Ajouter*/}
      {/*</Button>*/}
      {/*<AppDialog*/}
      {/*  title="Nouvelle opportunité"*/}
      {/*  open={newCardDialogOpen}*/}
      {/*  onClose={() => setNewCardDialogOpen(false)}*/}
      {/*  dialogContent={*/}
      {/*    <OpportunityDetails onSaved={() => setNewCardDialogOpen(false)} />*/}
      {/*  }*/}
      {/*  maxWidth="md"*/}
      {/*/>*/}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {opportunities.map((opportunity, index) =>
          (() =>
            !isVirtualOpportunity(opportunity) ? (
              <OpportunityCard
                key={opportunity.id}
                opportunity={opportunity}
              ></OpportunityCard>
            ) : (
              <Box
                key={opportunity.id}
                sx={{
                  marginY: -2,
                  backgroundColor: theme.palette.primary.main,
                  opacity:
                    opportunity?.status === status &&
                    opportunity?.order === index
                      ? 0
                      : 0.25,
                  height: '1rem',
                  borderRadius: 1,
                }}
              ></Box>
            ))()
        )}
      </Box>
    </Paper>
  );
};

export default OpportunityColumn;
