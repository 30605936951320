import { configureStore } from '@reduxjs/toolkit';
import { api, tagTypes } from 'src/services/api.service';
import authSlice from 'src/store/reducers/authSlice';
import dataGridSlice from 'src/store/reducers/dataGridSlice';
import opportunitySlice from 'src/store/reducers/opportunitiesSlice';
export const applicationReducer = {
  [authSlice.name]: authSlice.reducer,
  [dataGridSlice.name]: dataGridSlice.reducer,
  [opportunitySlice.name]: opportunitySlice.reducer,
  [api.reducerPath]: api.reducer,
};

export const applicationMiddleware = (getDefaultMiddleware: any) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }).concat(api.middleware);

export const store = configureStore({
  reducer: applicationReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const logout = () => async (dispatch: AppDispatch) => {
  console.log('logout');
  dispatch(authSlice.actions.signoutUser());
  dispatch(api.util.invalidateTags(tagTypes));
};
