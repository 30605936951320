import { Box, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { fr } from 'date-fns/locale';
import React from 'react';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import FetchInterceptor from 'src/components/FetchInterceptor/FetchInterceptor';
import Footer from 'src/components/Footer/Footer';
import Header from 'src/components/Header/Header';
import ScrollToTop from 'src/components/ScrollToTop/ScrollToTop';
import { useAuthentication } from 'src/hooks/useAuthentication';
import AccountView from 'src/views/AccountView/AccountView';
import ResetPasswordView from 'src/views/ResetPasswordView/ResetPasswordView';
import SetPasswordView from 'src/views/SetPasswordView/SetPasswordView';
import SigninView from 'src/views/SigninView/SigninView';
import SignupView from 'src/views/SignupView/SignupView';
import './App.scss';
import { store } from './store/store';
import { theme } from './theme';

function AppWrapper() {
  // if (config.googleAnalyticsTrackingCode) {
  //   ReactGA.initialize(config.googleAnalyticsTrackingCode);
  // }

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
          <ScrollToTop />
          <App />
        </LocalizationProvider>
      </Provider>
    </ThemeProvider>
  );
}

function App() {
  const { isAuthenticated, availableRoutes } = useAuthentication();

  FetchInterceptor();

  return (
    <React.Suspense fallback={<></>}>
      <Header />

      <Box
        sx={{
          position: 'absolute',
          top: { xs: 56, md: 80 },
          width: '100%',
          paddingBottom: '3rem',
        }}
      >
        <Routes>
          {isAuthenticated && [
            ...availableRoutes.map((route) => (
              <Route
                path={route.path}
                element={<route.component />}
                key={route.key}
              />
            )),
            <Route
              path="/mon-compte"
              element={<AccountView />}
              key="account_route"
            />,
            <Route
              path="/"
              element={<Navigate replace to="/annonces" />}
              key="root_route"
            />,
          ]}
          {!isAuthenticated && [
            <Route
              path="/connexion"
              element={<SigninView />}
              key="signin_route"
            />,
            <Route
              path="/inscription"
              element={<SignupView />}
              key="signup_route"
            />,
            <Route
              path="/mot-de-passe-oublie"
              element={<ResetPasswordView />}
              key="reset_password_route"
            />,
            <Route
              path="/mot-de-passe"
              element={<SetPasswordView />}
              key="signin_route"
            />,
            <Route path="/" element={<SigninView />} key="root_route" />,
          ]}
          <Route path="/*" element={<Navigate replace to="/" />} />
        </Routes>
      </Box>
      <Footer />
    </React.Suspense>
  );
}

export default AppWrapper;
