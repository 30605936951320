import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react';
import { TeamToCreate } from 'shared/schema/Team/Team';
import AddTeamDialog from 'src/components/TeamList/AddTeamDialog';
import { useCreateTeamMutation } from 'src/services/team.service';

const AddTeamButton = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const [createTeam] = useCreateTeamMutation();

  const submitTeam = async (team: TeamToCreate) => {
    await createTeam(team);
    setDialogOpen(false);
  };

  return (
    <>
      <Button startIcon={<Add />} onClick={() => setDialogOpen(true)}>
        Ajouter une équipe
      </Button>
      <AddTeamDialog
        onSubmit={submitTeam}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

export default AddTeamButton;
