import * as React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

interface Props {
  onNavigate?: () => void;
}
const AccountForgotPasswordButton = ({ onNavigate }: Props) => {
  const navigate = useNavigate();
  return (
    <Button
      variant="text"
      onClick={() => {
        navigate('/mot-de-passe-oublie');
        onNavigate?.();
      }}
    >
      Mot de passe oublié ?
    </Button>
  );
};

export default AccountForgotPasswordButton;
