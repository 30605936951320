import { Box, Button, FormHelperText, TextField } from '@mui/material';
import { useState } from 'react';
import { Email } from 'shared/schema/SignIn';
import AppError from 'src/components/_app/AppError/AppError';
import { useForm } from 'src/hooks/useForm';
import { useForgotPasswordMutation } from 'src/services/account.service';
import { z } from 'zod';
const AccountForgotPasswordForm = () => {
  const [email, setEmail] = useState('');

  const [forgotPassword, { isError: isForgotPasswordError }] =
    useForgotPasswordMutation({
      fixedCacheKey: 'shared-forgot-password',
    });

  const Form = z.object({
    email: Email,
  });

  const { message, validate, messageType } = useForm(Form, {
    email,
  });

  const submit = async () => {
    await validate(async () => {
      await forgotPassword(email);
    });
  };

  return (
    <Box component="form" sx={{ paddingY: '3rem' }}>
      <TextField
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        autoFocus
        label="Votre email"
        type="email"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('email') === 'error'}
        required
      />
      <FormHelperText error>{message('email')}</FormHelperText>
      <AppError open={isForgotPasswordError} />
      <Button
        variant="contained"
        onClick={submit}
        sx={{ marginTop: '2rem' }}
        fullWidth
      >
        Soumettre
      </Button>
    </Box>
  );
};

export default AccountForgotPasswordForm;
