import { Box } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridSortDirection,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridValidRowModel,
} from '@mui/x-data-grid';
import _ from 'lodash';
import AddTeamButton from 'src/components/TeamList/AddTeamButton';
import AppSnackbar from 'src/components/_app/AppSnackbar/AppSnackbar';
import { useDataGrid } from 'src/hooks/useDataGrid';
import {
  useFindTeamsQuery,
  useUpdateTeamMutation,
} from 'src/services/team.service';

const TeamList = () => {
  const { apiRef, initialStateFilter } = useDataGrid('team');

  const initialState = {
    ...initialStateFilter,
    sorting: {
      sortModel: [{ field: 'department', sort: 'asc' as GridSortDirection }],
    },
  };

  const [updateTeam, { isSuccess: isUpdateSuccess }] = useUpdateTeamMutation();

  const { data } = useFindTeamsQuery();
  const teams = data ?? [];

  const processRowUpdate = async (
    updatedRow: GridValidRowModel,
    originalRow: GridValidRowModel
  ) => {
    if (!_.isEqual(updatedRow, originalRow)) {
      await updateTeam({
        teamId: updatedRow.id,
        teamUpdate: {
          name: updatedRow.name,
        },
      });
    }
    return updatedRow;
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nom',
      editable: true,
      width: 500,
    },
    {
      field: 'users',
      headerName: 'Nombre de membres',
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return params.row.users.length;
      },
    },
  ];

  const toolbar = () => (
    <GridToolbarContainer>
      <AddTeamButton />
      <Box sx={{ flexGrow: 1 }}></Box>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );

  return (
    <>
      <AppSnackbar
        isOpen={isUpdateSuccess}
        severity="success"
        message="Modification enregistrée"
      />
      <Box height="calc(100vh - 250px)">
        <DataGrid
          apiRef={apiRef}
          initialState={initialState}
          rows={teams}
          columns={columns}
          disableColumnSelector
          disableRowSelectionOnClick
          processRowUpdate={processRowUpdate}
          hideFooter
          slots={{ toolbar }}
        />
      </Box>
    </>
  );
};

export default TeamList;
