import React from 'react';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

interface Props {
  onClose?: () => void;
}

const AppCloseButton = ({ onClose }: Props) => {
  return onClose ? (
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: 'absolute',
        right: '0.5rem',
        top: '0.5rem',
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <Close />
    </IconButton>
  ) : (
    <></>
  );
};

export default AppCloseButton;
