import { z } from 'zod';

export const TeamOpportunityNoteToCreate = z.object({
  content: z.string(),
});

export const TeamOpportunityNote = z.object({
  id: z.string().uuid(),
  teamId: z.string().uuid(),
  opportunityId: z.string().uuid(),
  authorId: z.string().uuid(),
  content: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date().nullish(),
});

export type TeamOpportunityNote = z.infer<typeof TeamOpportunityNote>;
export type TeamOpportunityNoteToCreate = z.infer<
  typeof TeamOpportunityNoteToCreate
>;
