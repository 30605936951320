export function pluralize(
  count: number,
  replacements?: { old: string; new: string }[]
) {
  return (str: string): string =>
    str
      .split(' ')
      .map((s) =>
        count > 1 ? replacements?.find((_) => _.old === s)?.new ?? `${s}s` : s
      )
      .join(' ');
}

export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const completeMatch = cleaned.match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/);
  if (completeMatch) {
    return `${completeMatch[1]} ${completeMatch[2]} ${completeMatch[3]} ${completeMatch[4]} ${completeMatch[5]}`;
  }

  const incompleteMatch = cleaned.match(
    /^(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})$/
  );
  if (incompleteMatch) {
    return `0${incompleteMatch[1]} ${incompleteMatch[2]} ${incompleteMatch[3]} ${incompleteMatch[4]} ${incompleteMatch[5]}`;
  }

  return phoneNumber;
};
