import { z } from 'zod';

export const Gearbox = z.enum(['Manual', 'Automatic']);

export type Gearbox = z.infer<typeof Gearbox>;

export const GearboxList: Gearbox[] = Gearbox.options;

export const GearboxLabel: Record<Gearbox, string> = {
  Manual: 'Manuelle',
  Automatic: 'Automatique',
};
