import React from 'react';
import { Alert } from '@mui/material';

interface Props {
  open: boolean;

  message?: string;
}

const AppError = ({ open, message }: Props) => {
  return open ? (
    <Alert severity="error">
      {message ?? "Une erreur s'est produite, veuillez réessayer."}
    </Alert>
  ) : (
    <></>
  );
};

export default AppError;
