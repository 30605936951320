import { z } from 'zod';
export const Fuel = z.enum([
  'Diesel',
  'Electric',
  'Gasoline',
  'Hybrid',
  'GPL',
  'Other',
]);

export type Fuel = z.infer<typeof Fuel>;

export const FuelList: Fuel[] = Fuel.options;

export const FuelLabel: Record<Fuel, string> = {
  Diesel: 'Diesel',
  Electric: 'Électrique',
  Gasoline: 'Essence',
  Hybrid: 'Hybride',
  GPL: 'GPL',
  Other: 'Autre',
};
