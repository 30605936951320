import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Grid2 as Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  OpportunityStatus,
  OpportunityStatusList,
} from 'shared/schema/Opportunity/OpportunityStatus';
import { TeamOpportunity } from 'shared/schema/TeamOpportunity/TeamOpportunity';
import OpportunityCard from 'src/components/OpportunityBoard/OpportunityCard';
import OpportunityColumn, {
  isVirtualOpportunity,
  OpportunityColumnProps,
} from 'src/components/OpportunityBoard/OpportunityColumn';
import { useUpdateTeamOpportunityMutation } from 'src/services/teamOpportunity.service';

interface Props {
  opportunities: TeamOpportunity[];
}

const OpportunityBoard = ({ opportunities }: Props) => {
  const [columns, setColumns] = useState<OpportunityColumnProps[]>([]);

  const [updateOpportunity] = useUpdateTeamOpportunityMutation();
  const [draggedOpportunity, setDraggedOpportunity] =
    useState<TeamOpportunity>();

  useEffect(() => {
    setColumns(
      OpportunityStatusList.map((status) => ({
        status,
        opportunities: [
          ...(opportunities ?? [])
            .filter((o) => o.status === status)
            .sort((o1, o2) => o1.order - o2.order),
        ],
      }))
    );
  }, [opportunities]);

  type ColumnId = string | OpportunityStatus;

  const findColumn = (unique?: ColumnId) => {
    if (!unique) {
      return null;
    }
    if (OpportunityStatusList.includes(unique as OpportunityStatus)) {
      return columns.find((c) => c.status === unique);
    }
    return columns.find((c) => c.opportunities.some((o) => o.id === unique));
  };

  const handleDragStart = (event: DragStartEvent) => {
    const activeOpportunity = opportunities.find(
      (o) => o.id === event.active.id
    );
    if (activeOpportunity) {
      setDraggedOpportunity(activeOpportunity);
    }
  };

  const handleDragOver = async (event: DragOverEvent) => {
    const { over } = event;
    const overColumn = findColumn(over?.id as ColumnId);
    if (!overColumn || !draggedOpportunity) {
      return null;
    }
    const overItems = overColumn.opportunities.filter(
      (i) => !isVirtualOpportunity(i)
    );
    const overIndex = overItems.findIndex(
      (opportunity) => opportunity.id === over?.id
    );
    const newIndex = overIndex >= 0 ? overIndex : overItems.length;

    setColumns((prevState) => {
      return prevState.map((c) => {
        if (c.status === overColumn.status) {
          c.opportunities = [
            ...overItems.slice(0, newIndex),
            {
              ...draggedOpportunity,
              id: `${draggedOpportunity.id}-virtual`,
              isVirtual: true,
            },
            ...overItems.slice(newIndex, overItems.length),
          ];
          return c;
        } else {
          c.opportunities = c.opportunities.filter(
            (i) => !isVirtualOpportunity(i)
          );
          return c;
        }
      });
    });
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    setDraggedOpportunity(undefined);
    const { over } = event;
    const overColumn = findColumn(over?.id as ColumnId);
    if (!overColumn) {
      return null;
    }
    const overIndex = overColumn.opportunities.findIndex((opportunity) =>
      isVirtualOpportunity(opportunity)
    );

    setColumns((prevState) => {
      return prevState.map((column) => ({
        ...column,
        opportunities: column.opportunities
          .filter((opportunity) => opportunity.id !== draggedOpportunity?.id)
          .map((opportunity) => ({
            ...opportunity,
            id: opportunity.id.replace('-virtual', ''),
            isVirtual: false,
          })),
      }));
    });

    if (
      draggedOpportunity !== undefined &&
      (draggedOpportunity.status !== overColumn.status ||
        draggedOpportunity.order !== overIndex)
    ) {
      await updateOpportunity({
        ...draggedOpportunity,
        opportunityId: draggedOpportunity.id,
        status: overColumn.status,
        order: overIndex,
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      // collisionDetection={rectIntersection}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
    >
      <Grid container spacing={2} wrap="nowrap">
        {columns.map((column) => (
          <OpportunityColumn
            key={column.status}
            status={column.status}
            opportunities={column.opportunities}
          ></OpportunityColumn>
        ))}
      </Grid>
      <DragOverlay>
        {draggedOpportunity ? (
          <OpportunityCard opportunity={draggedOpportunity} />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default OpportunityBoard;
