import { GridFilterItem } from '@mui/x-data-grid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const DataGridStateKey = 'DataGridState';

export interface DataGridState {
  quickFilterValues: string[];
  filterItems?: GridFilterItem[];
}

export type DataGridName = 'team' | 'user';

const defaultDataGridState = {
  quickFilterValues: [],
};

const defaultState: Record<DataGridName, DataGridState> = {
  team: defaultDataGridState,
  user: defaultDataGridState,
};

const initialState = () => {
  if (localStorage.getItem(DataGridStateKey)) {
    const state = JSON.parse(localStorage.getItem(DataGridStateKey)!);
    return {
      ...state,
    };
  } else {
    return defaultState;
  }
};

const dataGridSlice = createSlice({
  name: 'dataGrid',
  initialState: initialState() as Record<DataGridName, DataGridState>,
  reducers: {
    changeQuickFilterValues: (
      state,
      action: PayloadAction<{ name: DataGridName; values: string[] }>
    ) => {
      const { name, values } = action.payload;
      state[name].quickFilterValues = values;
      localStorage.setItem(DataGridStateKey, JSON.stringify(state));
    },
    changeFilterItems: (
      state,
      action: PayloadAction<{ name: DataGridName; items: GridFilterItem[] }>
    ) => {
      const { name, items } = action.payload;
      state[name].filterItems = items;
      localStorage.setItem(DataGridStateKey, JSON.stringify(state));
    },
    reset: () => {
      localStorage.removeItem(DataGridStateKey);
      return defaultState;
    },
  },
});

export default dataGridSlice;
