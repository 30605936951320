import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import fp from 'lodash';
import { FindOpportunityOptions } from 'shared/schema/Opportunity/FindOpportunityOptions';

type OpportunityState = {
  findOpportunityOptions: FindOpportunityOptions;
};

const opportunitySlice = createSlice({
  name: 'opportunities',
  initialState: {
    findOpportunityOptions: {
      status: undefined,
      hasPhone: undefined,
    },
  } as OpportunityState,
  reducers: {
    changeFindOptions: (
      state,
      action: PayloadAction<Partial<FindOpportunityOptions>>
    ) => {
      state.findOpportunityOptions = fp.omitBy(
        {
          ...state.findOpportunityOptions,
          ...action.payload,
        },
        fp.isNil
      );
    },
  },
});

export default opportunitySlice;
