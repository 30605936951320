import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DraftUser } from 'shared/schema/User/User';
import AppError from 'src/components/_app/AppError/AppError';
import { useForm } from 'src/hooks/useForm';
import { useSignUpMutation } from 'src/services/account.service';
import { z } from 'zod';

interface Props {
  onNavigate?: () => void;
}

const AccountSignupForm = ({ onNavigate }: Props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [agreement, setAgreemnet] = useState(false);

  const [signUp, { isError: isSignUpError }] = useSignUpMutation({
    fixedCacheKey: 'shared-signup',
  });

  const Form = z.object({
    ...DraftUser.shape,
    passwordConfirm: z
      .string({
        required_error: 'Veuillez confirmer votre mot de passe',
      })
      .min(1, 'Veuillez confirmer votre mot de passe.'),
    agreement: z.boolean().refine((val) => val === true, {
      message: "Veuillez accepter les conditions générales d'utilisation",
    }),
  });

  const FormRefinement = Form.refine(
    ({ password, passwordConfirm }) => password === passwordConfirm,
    {
      path: ['passwordConfirm'],
      message: 'Les mots de passe ne correspondent pas',
    }
  );

  const { message, validate, messageType } = useForm(FormRefinement, {
    email,
    password,
    passwordConfirm,
    firstName,
    lastName,
    agreement,
  });

  const submit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    await validate(async () => {
      await signUp({
        email: email as string,
        password: password as string,
        firstName: firstName as string,
        lastName: lastName as string,
      }).unwrap();
    });
  };

  return (
    <Box component="form">
      <TextField
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        autoFocus
        label="Votre email"
        type="email"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('email') === 'error'}
        required
      />
      <FormHelperText error>{message('email')}</FormHelperText>
      <TextField
        value={firstName}
        onChange={(event) => setFirstName(event.target.value)}
        label="Votre prénom"
        type="text"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('firstName') === 'error'}
        required
      />
      <FormHelperText error>{message('firstName')}</FormHelperText>
      <TextField
        value={lastName}
        onChange={(event) => setLastName(event.target.value)}
        label="Votre nom"
        type="text"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('lastName') === 'error'}
        required
      />
      <FormHelperText error>{message('lastName')}</FormHelperText>
      <TextField
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        label="Mot de passe"
        type="password"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('password') === 'error'}
        required
      />
      <FormHelperText error>{message('password')}</FormHelperText>
      <TextField
        value={passwordConfirm}
        onChange={(event) => setPasswordConfirm(event.target.value)}
        label="Confirmation du mot de passe"
        type="password"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('passwordConfirm') === 'error'}
        required
      />
      <FormHelperText error>{message('passwordConfirm')}</FormHelperText>
      <FormControlLabel
        control={
          <Checkbox
            checked={agreement}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setAgreemnet(e.target.checked)
            }
          />
        }
        label="J'ai lu et j'accepte les CGU"
      />
      <FormHelperText error>{message('agreement')}</FormHelperText>
      <AppError open={isSignUpError} />
      <Button
        variant="contained"
        onClick={submit}
        sx={{ marginY: '2rem' }}
        fullWidth
      >
        Créer mon compte
      </Button>
      <Divider sx={{ marginY: '1rem' }}>OU</Divider>
      <Typography variant="h6">Vous avez déjà un compte ?</Typography>
      <Button
        variant="outlined"
        onClick={() => {
          navigate('/connexion');
          onNavigate?.();
        }}
        fullWidth
        sx={{ marginY: '1rem' }}
      >
        Connectez-vous
      </Button>
    </Box>
  );
};

export default AccountSignupForm;
