import {
  Close,
  Euro,
  LocalGasStation,
  Notifications,
  Person,
  Phone,
  Share,
} from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Slider,
  Switch,
  Typography,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import { useMemo, useState } from 'react';
import { FindOpportunityOptions } from 'shared/schema/Opportunity/FindOpportunityOptions';
import { TeamOpportunity } from 'shared/schema/TeamOpportunity/TeamOpportunity';
import { Fuel, FuelLabel, FuelList } from 'shared/schema/Vehicle/Fuel';
import {
  Gearbox,
  GearboxLabel,
  GearboxList,
} from 'shared/schema/Vehicle/Gearbox';
import { toArray } from 'shared/utils/utils';
import OpportunitiesPreview from 'src/components/OpportunitiesPreview/OpportunitiesPreview';
import AppWithIcon from 'src/components/_app/AppWithIcon/AppWithIcon';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { useAppSelector } from 'src/hooks/useStore';
import { pluralize } from 'src/utils/stringUtils';
import './FilterMenu.scss';
interface Props {
  onChangeFilter: (findFilter: Partial<FindOpportunityOptions>) => void;
  opportunitiesToRemind: TeamOpportunity[];
}

const MinPrice = 0;
const MaxPrice = 100000;

const FilterMenu = ({ onChangeFilter, opportunitiesToRemind }: Props) => {
  const { team, getTeamUser, hasTeam } = useAuthentication();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { findOpportunityOptions } = useAppSelector(
    (state) => state.opportunities
  );

  const [priceRange, setPriceRange] = useState<number[]>([
    findOpportunityOptions.minPrice ?? MinPrice,
    findOpportunityOptions.maxPrice ?? MaxPrice,
  ]);

  const filtersCount = useMemo(() => {
    return (
      (findOpportunityOptions.hasReminder ? 1 : 0) +
      (findOpportunityOptions.hasPhone ? 1 : 0) +
      ((((findOpportunityOptions.minPrice ?? MinPrice) !== MinPrice ||
        findOpportunityOptions.maxPrice) ??
        MaxPrice) !== MaxPrice
        ? 1
        : 0) +
      ((findOpportunityOptions.managedBy ?? []).length > 0 ? 1 : 0) +
      ((findOpportunityOptions.gearbox ?? []).length > 0 ? 1 : 0) +
      ((findOpportunityOptions.fuel ?? []).length > 0 ? 1 : 0)
    );
  }, [findOpportunityOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const reinitializeFilters = () => {
    setPriceRange([MinPrice, MaxPrice]);
    onChangeFilter({
      hasReminder: undefined,
      hasPhone: undefined,
      minPrice: MinPrice,
      maxPrice: MaxPrice,
      managedBy: [],
      gearbox: [],
      fuel: [],
    });
  };

  const toggleDrawer = (): void => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-menu">
      <Badge badgeContent={filtersCount} color="primary">
        <Button
          onClick={toggleDrawer}
          color="secondary"
          variant="outlined"
          aria-label="Toggle filter menu"
          startIcon={<FilterListIcon />}
          sx={{ marginLeft: 1 }}
        >
          Filtres
        </Button>
      </Badge>
      {opportunitiesToRemind.length > 0 && (
        <OpportunitiesPreview
          teamOpportunities={opportunitiesToRemind}
          previewButtonProps={{
            startIcon: <Notifications />,
            children: (
              <>
                {opportunitiesToRemind.length}{' '}
                {pluralize(opportunitiesToRemind.length)('annonce')} à rappeler
              </>
            ),
          }}
        />
      )}
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <TextField
          label="Rechercher..."
          type="search"
          size="small"
          sx={{ width: 300 }}
          onChange={(event) =>
            onChangeFilter({
              query: event.target.value,
            })
          }
        />
      </Box>
      <Drawer
        variant="persistent"
        anchor="left"
        open={isOpen}
        sx={{
          transition: 'width 0.3s ease',
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            transition: 'width 0.3s ease',
          },
        }}
      >
        <Box
          sx={{
            width: isOpen ? 240 : 56,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <AppWithIcon
            icon={
              <IconButton onClick={toggleDrawer} aria-label="Close filter menu">
                <Close />
              </IconButton>
            }
            iconPosition="float-right"
          >
            <Typography variant="h6" paddingX={2} paddingTop={2}>
              Filtres ({filtersCount})
            </Typography>
          </AppWithIcon>
          <Button
            size="small"
            onClick={reinitializeFilters}
            sx={{ alignSelf: 'flex-start', marginLeft: 1 }}
          >
            Réinitialiser
          </Button>
          <Divider />
          <List subheader={<ListSubheader>Annonce</ListSubheader>}>
            {hasTeam && (
              <ListItem>
                <ListItemIcon sx={{ alignSelf: 'flex-start', marginTop: 1 }}>
                  <Person />
                </ListItemIcon>
                <Box width={'100%'}>
                  <FormControl fullWidth={true} size="small">
                    <InputLabel id="user-select-label">Géré par</InputLabel>
                    <Select
                      labelId="user-select-label"
                      label={'Géré par'}
                      multiple
                      value={findOpportunityOptions.managedBy ?? []}
                      onChange={(event) => {
                        onChangeFilter({
                          managedBy: toArray(event.target.value),
                        });
                      }}
                      renderValue={(selected) =>
                        toArray(selected)
                          .map((value) =>
                            team?.users?.find((_) => _.id === value)
                          )
                          .map((user) => `${user?.firstName}`)
                          .join(', ')
                      }
                    >
                      {team?.users?.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                          <Checkbox
                            checked={(
                              findOpportunityOptions.managedBy ?? []
                            ).includes(user.id)}
                          />
                          <ListItemText
                            primary={`${user.firstName} ${user.lastName}`}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {findOpportunityOptions.managedBy?.map((userId) => (
                    <Chip
                      sx={{ marginTop: 1, marginRight: 1, width: 'auto' }}
                      label={
                        getTeamUser(userId)
                          ? `${getTeamUser(userId)?.firstName} ${
                              getTeamUser(userId)?.lastName
                            }`
                          : 'Non attribué'
                      }
                      size="small"
                      color="primary"
                      onDelete={() =>
                        onChangeFilter({
                          managedBy: (
                            findOpportunityOptions.managedBy ?? []
                          ).filter((id) => id !== userId),
                        })
                      }
                    />
                  ))}
                </Box>
              </ListItem>
            )}
            <ListItem>
              <ListItemIcon sx={{ alignSelf: 'flex-start', marginTop: 1 }}>
                <Notifications />
              </ListItemIcon>
              <ListItemText primary="À rappeler" />
              <Switch
                edge="end"
                checked={findOpportunityOptions.hasReminder ?? false}
                onChange={(event) =>
                  onChangeFilter({
                    hasReminder: event.target.checked,
                  })
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Phone />
              </ListItemIcon>
              <ListItemText primary="Téléphone" />
              <Switch
                edge="end"
                checked={findOpportunityOptions.hasPhone ?? false}
                onChange={(event) =>
                  onChangeFilter({
                    hasPhone: event.target.checked,
                  })
                }
              />
            </ListItem>
          </List>
          <Divider />
          <List
            subheader={
              <ListSubheader>
                <Box sx={{ display: 'inline', marginRight: 3 }}>Prix</Box>{' '}
                {priceRange[0]} € - {priceRange[1]} €
              </ListSubheader>
            }
          >
            <ListItem>
              <ListItemIcon>
                <Euro />
              </ListItemIcon>
              <Slider
                value={priceRange}
                onChange={(_, newValue) => setPriceRange(newValue as number[])}
                onChangeCommitted={(_) =>
                  onChangeFilter({
                    minPrice: priceRange[0],
                    maxPrice: priceRange[1],
                  })
                }
                min={MinPrice}
                max={MaxPrice}
                step={1000}
                valueLabelDisplay="off"
              />
            </ListItem>
          </List>
          <Divider />
          <List subheader={<ListSubheader>Véhicule</ListSubheader>}>
            <ListItem>
              <ListItemIcon>
                <Share />
              </ListItemIcon>
              <FormControl fullWidth={true} size="small">
                <InputLabel id="user-select-label">Boite de vitesse</InputLabel>
                <Select
                  labelId="gearbox-select-label"
                  label={'Boite de vitesse'}
                  multiple
                  value={findOpportunityOptions.gearbox ?? []}
                  onChange={(event) => {
                    onChangeFilter({
                      gearbox: toArray(event.target.value),
                    });
                  }}
                  renderValue={(selected) =>
                    toArray(selected)
                      .map((value) => GearboxLabel[value as Gearbox])
                      .join(', ')
                  }
                >
                  {GearboxList.map((gearbox) => (
                    <MenuItem key={gearbox} value={gearbox}>
                      <Checkbox
                        checked={(
                          findOpportunityOptions.gearbox ?? []
                        ).includes(gearbox)}
                      />
                      <ListItemText primary={GearboxLabel[gearbox]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <LocalGasStation />
              </ListItemIcon>
              <FormControl fullWidth={true} size="small">
                <InputLabel id="fuel-select-label">Carburant</InputLabel>
                <Select
                  labelId="fuel-select-label"
                  label={'Carburant'}
                  multiple
                  value={findOpportunityOptions.fuel ?? []}
                  onChange={(event) => {
                    onChangeFilter({
                      fuel: toArray(event.target.value),
                    });
                  }}
                  renderValue={(selected) =>
                    toArray(selected)
                      .map((value) => FuelLabel[value as Fuel])
                      .join(', ')
                  }
                >
                  {FuelList.map((fuel) => (
                    <MenuItem key={fuel} value={fuel}>
                      <Checkbox
                        checked={(findOpportunityOptions.fuel ?? []).includes(
                          fuel
                        )}
                      />
                      <ListItemText primary={FuelLabel[fuel]} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default FilterMenu;
