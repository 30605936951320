import { z } from 'zod';
import { UserInfos } from '../User/User';
import { TeamKind } from './TeamKind';

export const TeamToCreate = z.object({
  name: z
    .string({
      required_error: 'Veuillez renseigner un nom',
    })
    .min(1, 'Veuillez renseigner un nom'),
});

export const Team = z.object({
  ...TeamToCreate.shape,
  id: z.string().uuid(),
  kind: TeamKind,
  scrapperId: z.string().nullish(),
});

export const TeamWithUsers = Team.merge(
  z.object({
    users: z.array(
      UserInfos.pick({ id: true, firstName: true, lastName: true, role: true })
    ),
  })
);

export const TeamUpdate = Team.pick({
  name: true,
});

export type Team = z.infer<typeof Team>;
export type TeamToCreate = z.infer<typeof TeamToCreate>;
export type TeamUpdate = z.infer<typeof TeamUpdate>;
export type TeamWithUsers = z.infer<typeof TeamWithUsers>;
