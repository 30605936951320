import { Notifications, Person } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { useState } from 'react';
import {
  TeamOpportunity,
  TeamOpportunityUpdate,
} from 'shared/schema/TeamOpportunity/TeamOpportunity';
import { useAuthentication } from 'src/hooks/useAuthentication';
import {
  useCreateTeamOpportunityNoteMutation,
  useFindTeamOpportunityNotesQuery,
  useUpdateTeamOpportunityMutation,
} from 'src/services/teamOpportunity.service';
interface Props {
  teamOpportunity: TeamOpportunity;
}

const OpportunityManagement = ({ teamOpportunity }: Props) => {
  const { hasTeam, team, getTeamUser } = useAuthentication();

  const [updateOpportunity] = useUpdateTeamOpportunityMutation();
  const [createNote] = useCreateTeamOpportunityNoteMutation();

  const { data: notes } = useFindTeamOpportunityNotesQuery(teamOpportunity);

  const [noteContent, setNoteContent] = useState('');

  const isMidnight = (date: Date) =>
    date.getHours() === 0 && date.getMinutes() === 0;

  return (
    <>
      <List>
        {hasTeam && (
          <ListItem>
            <ListItemIcon sx={{ alignSelf: 'flex-start', marginTop: 1 }}>
              <Person />
            </ListItemIcon>
            {teamOpportunity.managedBy ? (
              <Chip
                sx={{ marginLeft: 1, marginTop: 1 }}
                label={
                  getTeamUser(teamOpportunity.managedBy)
                    ? `${getTeamUser(teamOpportunity.managedBy)?.firstName} ${
                        getTeamUser(teamOpportunity.managedBy)?.lastName
                      }`
                    : 'Non attribué'
                }
                size="small"
                color="primary"
                onDelete={async () => {
                  await updateOpportunity({
                    opportunityId: teamOpportunity.id,
                    ...TeamOpportunityUpdate.parse({
                      ...teamOpportunity,
                      managedBy: undefined,
                    }),
                  });
                }}
              />
            ) : (
              <FormControl fullWidth={true} size="small">
                <InputLabel id="user-select-label">Géré par</InputLabel>
                <Select
                  labelId="user-select-label"
                  label={'Géré par'}
                  value={teamOpportunity.managedBy || ''}
                  onChange={async (e) => {
                    await updateOpportunity({
                      opportunityId: teamOpportunity.id,
                      ...TeamOpportunityUpdate.parse({
                        ...teamOpportunity,
                        managedBy: e.target.value as string,
                      }),
                    });
                  }}
                >
                  {team?.users?.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.firstName} {user.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </ListItem>
        )}
        <ListItem>
          <ListItemIcon sx={{ alignSelf: 'flex-start', marginTop: 1 }}>
            <Notifications />
          </ListItemIcon>
          {teamOpportunity.reminderDate ? (
            <Chip
              sx={{ marginLeft: 1, marginTop: 1 }}
              label={format(
                teamOpportunity.reminderDate,
                `dd/MM/yyyy ${
                  isMidnight(teamOpportunity.reminderDate) ? '' : 'à HH:mm'
                }`
              )}
              size="small"
              color="primary"
              onDelete={async () => {
                await updateOpportunity({
                  opportunityId: teamOpportunity.id,
                  ...TeamOpportunityUpdate.parse({
                    ...teamOpportunity,
                    reminderDate: undefined,
                  }),
                });
              }}
            />
          ) : (
            <DateTimePicker
              label="Date de rappel"
              value={teamOpportunity.reminderDate}
              onAccept={async (date) => {
                await updateOpportunity({
                  opportunityId: teamOpportunity.id,
                  ...TeamOpportunityUpdate.parse({
                    ...teamOpportunity,
                    reminderDate: date,
                  }),
                });
              }}
              closeOnSelect={false}
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
            />
          )}
        </ListItem>
      </List>
      <Divider
        sx={{
          marginY: 2,
        }}
      />
      {notes?.map((note) => (
        <Box key={note.id} marginY={2}>
          <Typography variant="body2" color="textSecondary">
            Le {format(note.createdAt, 'dd/MM/yyyy à HH:mm')}
            {hasTeam && (
              <>
                {' par '}
                {getTeamUser(note.authorId)
                  ? `${getTeamUser(note.authorId)?.firstName} ${
                      getTeamUser(note.authorId)?.lastName
                    }`
                  : 'Inconnu'}
              </>
            )}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
            }}
          >
            {note.content}
          </Typography>
        </Box>
      ))}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          gap: 1,
        }}
      >
        <FormControl fullWidth={true} size="small">
          <TextField
            label="Nouvelle note"
            multiline
            size="small"
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          disabled={!noteContent}
          onClick={async () => {
            await createNote({
              opportunityId: teamOpportunity.id,
              teamId: team?.id as string,
              teamOpportunityNoteToCreate: {
                content: noteContent,
              },
            });
            setNoteContent('');
          }}
          sx={{
            marginBottom: '2px',
          }}
        >
          Ajouter
        </Button>
      </Box>
    </>
  );
};

export default OpportunityManagement;
