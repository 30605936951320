import fp from 'lodash';
import { FindOpportunityOptions } from 'shared/schema/Opportunity/FindOpportunityOptions';
import {
  TeamOpportunity,
  TeamOpportunityUpdate,
} from 'shared/schema/TeamOpportunity/TeamOpportunity';
import {
  TeamOpportunityNote,
  TeamOpportunityNoteToCreate,
} from 'shared/schema/TeamOpportunityNote/TeamOpportunityNote';
import { api } from 'src/services/api.service';

export const opportunityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTeamOpportunity: builder.query<TeamOpportunity, string>({
      query: (teamOpportunityId) => `opportunities/${teamOpportunityId}`,
      transformResponse: (response: any) =>
        TeamOpportunity.parse(fp.omitBy(response, fp.isNil)),
      providesTags: (_result, _error, teamOpportunityId) => [
        { type: 'TeamOpportunity', id: teamOpportunityId },
      ],
    }),
    findTeamOpportunities: builder.query<
      TeamOpportunity[],
      FindOpportunityOptions
    >({
      query: (findOptions) => ({
        url: 'opportunities',
        params: findOptions,
      }),
      transformResponse: (response: any[]) =>
        response.map((_) => TeamOpportunity.parse(fp.omitBy(_, fp.isNil))),
      providesTags: (result) => [
        { type: 'TeamOpportunity', id: 'LIST' },
        ...(result ?? []).map(({ id }) => ({
          type: 'TeamOpportunity' as const,
          id,
        })),
      ],
    }),
    updateTeamOpportunity: builder.mutation<
      TeamOpportunity,
      TeamOpportunityUpdate & { opportunityId: string }
    >({
      query: ({ opportunityId, ...teamOpportunityUpdate }) => ({
        url: `opportunities/${opportunityId}`,
        method: 'PUT',
        body: teamOpportunityUpdate,
      }),
      transformResponse: (response: any) =>
        TeamOpportunity.parse(fp.omitBy(response, fp.isNil)),
      invalidatesTags: (_result, _error, { opportunityId }) => [
        { type: 'TeamOpportunity', id: 'LIST' },
        { type: 'TeamOpportunity', id: opportunityId },
      ],
    }),
    createTeamOpportunityNote: builder.mutation<
      void,
      {
        opportunityId: string;
        teamId: string;
        teamOpportunityNoteToCreate: TeamOpportunityNoteToCreate;
      }
    >({
      query: ({ opportunityId, teamId, teamOpportunityNoteToCreate }) => ({
        url: `opportunities/${opportunityId}/notes`,
        method: 'POST',
        body: teamOpportunityNoteToCreate,
      }),
      invalidatesTags: (_result, _error, { opportunityId }) => [
        { type: 'TeamOpportunity', id: opportunityId },
      ],
    }),
    findTeamOpportunityNotes: builder.query<
      TeamOpportunityNote[],
      TeamOpportunity
    >({
      query: (teamOpportunity) => `opportunities/${teamOpportunity.id}/notes`,
      transformResponse: (response: any[]) =>
        response.map((_) => TeamOpportunityNote.parse(fp.omitBy(_, fp.isNil))),
      providesTags: (_result, _error, teamOpportunity) => [
        { type: 'TeamOpportunity', id: teamOpportunity.id },
      ],
    }),
  }),
});

export const {
  useGetTeamOpportunityQuery,
  useFindTeamOpportunitiesQuery,
  useUpdateTeamOpportunityMutation,
  useCreateTeamOpportunityNoteMutation,
  useFindTeamOpportunityNotesQuery,
} = opportunityApi;
