import _ from 'lodash';
import { z } from 'zod';
import { Password, SignIn } from '../SignIn';
import { UserPermission } from './UserPermission';
import { UserRole, UserRolePermissions } from './UserRole';

export const DraftUser = z.object({
  ...SignIn.shape,
  firstName: z
    .string({
      required_error: 'Veuillez renseigner un prénom',
    })
    .min(1, 'Veuillez renseigner un prénom'),
  lastName: z
    .string({
      required_error: 'Veuillez renseigner un nom',
    })
    .min(1, 'Veuillez renseigner un nom'),
});

export const UserToCreate = DraftUser.omit({
  password: true,
}).merge(
  z.object({
    role: UserRole,
    teamId: z.string().uuid().nullish(),
  })
);

export const User = z.object({
  ...UserToCreate.shape,
  id: z.string().uuid(),
  createdAt: z.date(),
  activatedAt: z.date().nullish(),
  lastAuthenticatedAt: z.date().nullish(),
  password: Password.nullish(),
  teamId: z.string().uuid(),
});

export const UserUpdate = User.pick({
  email: true,
  firstName: true,
  lastName: true,
  role: true,
});

export const UserInfos = User.pick({
  id: true,
  email: true,
  firstName: true,
  lastName: true,
  role: true,
});

export const UserInfosUpdate = UserInfos.pick({
  firstName: true,
  lastName: true,
});

export const UserPasswordUpdate = z.object({
  oldPassword: Password,
  newPassword: Password,
});

export type DraftUser = z.infer<typeof DraftUser>;
export type UserToCreate = z.infer<typeof UserToCreate>;
export type UserUpdate = z.infer<typeof UserUpdate>;
export type User = z.infer<typeof User>;
export type UserInfos = z.infer<typeof UserInfos>;
export type UserInfosUpdate = z.infer<typeof UserInfosUpdate>;
export type UserPasswordUpdate = z.infer<typeof UserPasswordUpdate>;

export const hasPermission = (
  user: User | UserInfos,
  ...permissions: UserPermission[]
) => {
  const userPermissions = UserRolePermissions[user.role];

  return _.intersection(permissions, userPermissions).length > 0;
};
