import {
  Box,
  CircularProgress,
  Container,
  Grid2 as Grid,
  styled,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Brand } from 'shared/constants';
import home from 'src/assets/home.jpg';
import AccountSigninForm from 'src/components/Account/AccountSigninForm/AccountSigninForm';
import { H1 } from 'src/components/Styled/Styled';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';
import { useSignInMutation } from 'src/services/account.service';

const BackgroundImageSection = styled('section')(({ theme }) =>
  theme.unstable_sx({
    width: '100%',
    height: '100%',
    minHeight: {
      xs: '200px',
      sm: '282px',
      md: '375px',
      lg: '480px',
      xl: '100%',
    },
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    display: 'flex',
  })
);

const SigninView = () => {
  const navigate = useNavigate();
  useDocumentTitle('Connectez-vous');

  const [_, { isLoading: isSignInLoading }] = useSignInMutation(); // eslint-disable-line @typescript-eslint/no-unused-vars

  return (
    <Grid container spacing={2} sx={{ minHeight: 'calc(100vh - 128px)' }}>
      <Grid size={{ xs: 12, lg: 6 }}>
        <BackgroundImageSection sx={{ backgroundImage: `url(${home})` }}>
          <Container
            maxWidth="xl"
            sx={{
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}
          ></Container>
        </BackgroundImageSection>
      </Grid>
      <Grid size={{ xs: 12, lg: 6 }}>
        <Container>
          {isSignInLoading ? (
            <Box sx={{ textAlign: 'center', marginY: '10rem' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <H1>Connectez-vous à {Brand}</H1>
              <AccountSigninForm onConnect={() => navigate('/')} />
            </>
          )}
        </Container>
      </Grid>
    </Grid>
  );
};

export default SigninView;
