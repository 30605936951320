import { z } from 'zod';

export const Email = z
  .string()
  .min(1, 'Veuillez renseigner votre adresse email.')
  .email("L'adresse doit être un email valide");

export const Password = z
  .string()
  .min(1, 'Veuillez renseigner votre mot de passe.')
  .min(8, 'Au moins 8 caractères.')
  .regex(/[A-Z]/g, 'Au moins une majuscule.')
  .regex(/[a-z]/g, 'Au moins une minuscule.')
  .regex(/[0-9]/g, 'Au moins un chiffre.');

export const SignIn = z.object({
  email: Email,
  password: Password,
});

export type Email = z.infer<typeof Email>;
export type Password = z.infer<typeof Password>;
export type SignIn = z.infer<typeof SignIn>;
