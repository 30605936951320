import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  FormHelperText,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { TeamToCreate } from 'shared/schema/Team/Team';
import { useForm } from 'src/hooks/useForm';

export interface Props {
  onSubmit: (team: TeamToCreate) => Promise<void>;
  open: boolean;
  onClose: () => void;
}

function AddTeamDialog({ onSubmit, open, onClose }: Props) {
  const [name, setName] = useState('');

  const { message, validate, messageType } = useForm(TeamToCreate, {
    name,
  });

  const submit = async () => {
    await validate(
      async () =>
        await onSubmit({
          name,
        })
    );
  };

  return (
    <Box component="form" onSubmit={submit}>
      <Dialog open={open} scroll="paper" disableScrollLock fullWidth>
        <DialogTitle>Nouvelle équipe</DialogTitle>
        <DialogContent>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            autoFocus
            label="Nom de l'équipe"
            type="text"
            fullWidth
            variant="standard"
            margin="normal"
            error={messageType('name') === 'error'}
          />
          <FormHelperText error>{message('name')}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
          <Button variant="contained" type="submit" onClick={submit}>
            Créer l'équipe
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddTeamDialog;
