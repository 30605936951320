import { Owner } from 'shared/schema/Owner/Owner';
import { api } from 'src/services/api.service';

export const ownerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOwner: builder.query<Owner, string>({
      query: (ownerId) => `owners/${ownerId}`,
      transformResponse: (response: any) => Owner.parse(response),
      providesTags: (result, error, ownerId) => [
        { type: 'Owner', id: ownerId },
      ],
    }),
  }),
});

export const { useGetOwnerQuery } = ownerApi;
