import { Box, BoxProps } from '@mui/material';
import { ReactElement } from 'react';

type IconPosition = 'left' | 'right' | 'float-right';

interface Props extends BoxProps {
  icon: ReactElement;
  iconPosition?: IconPosition;
  iconSpacing?: string;
  children: string | ReactElement | ReactElement[];
}

const AppWithIcon = ({
  icon,
  children,
  iconPosition = 'left',
  iconSpacing = '0.5rem',
  ...props
}: Props) => {
  return (
    <Box
      {...props}
      sx={{
        ...props.sx,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      {iconPosition === 'left' && icon}
      <div
        style={
          iconPosition === 'left'
            ? { marginLeft: iconSpacing }
            : { marginRight: iconSpacing }
        }
      >
        {children}
      </div>
      {iconPosition === 'right' && icon}
      {iconPosition === 'float-right' && (
        <div style={{ flexGrow: 1, textAlign: 'right' }}>{icon}</div>
      )}
    </Box>
  );
};

export default AppWithIcon;
