import {
  AppBar,
  Box,
  Button,
  Container,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Brand } from 'shared/constants';
import AccountMenu from 'src/components/Account/AccountMenu/AccountMenu';
import HeaderMenu from 'src/components/Header/HeaderMenu';
import { useAuthentication } from 'src/hooks/useAuthentication';
import logo from '../../assets/logo.png';

const Header = () => {
  const location = useLocation();
  const { isAuthenticated, availableRoutes } = useAuthentication();
  return (
    <AppBar position="fixed" color="inherit">
      <Toolbar sx={{ paddingY: 1 }}>
        <Container maxWidth="xl" sx={{ display: 'flex', alignItems: 'center' }}>
          <HeaderMenu />
          <Link
            href="/"
            sx={{
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <Box
              component="img"
              alt="logo"
              src={logo}
              maxHeight={{ xs: '30px', sm: '50px' }}
            />
          </Link>
          <Box
            sx={{
              marginLeft: '10px',
              flexGrow: 1,
            }}
          >
            <Typography
              component="span"
              sx={{
                color: 'primary.main',
                flexGrow: { xs: 1, sm: 'unset' },
                fontSize: { xs: 20, sm: 25 },
                fontWeight: 'bold',
                marginBottom: '2px',
                marginRight: { sm: '3rem' },
                textAlign: { xs: 'center', sm: 'left' },
              }}
              data-testid="branding-typography"
            >
              {Brand}
            </Typography>
            <Typography sx={{ display: { xs: 'none', md: 'block' } }}>
              La solution pour les mandataires automobile !
            </Typography>
          </Box>
          {isAuthenticated &&
            availableRoutes.map((route) => (
              <Button
                href={route.path}
                sx={{ marginX: '1rem' }}
                key={route.key}
                color={
                  location.pathname === route.path ? 'primary' : 'secondary'
                }
              >
                {route.label}
              </Button>
            ))}
          {isAuthenticated ? (
            <AccountMenu />
          ) : (
            <>
              <Box
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  justifySelf: 'end',
                }}
              >
                <AccountMenu />
              </Box>
            </>
          )}
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
