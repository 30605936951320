import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AppCloseButton from 'src/components/_app/AppCloseButton/AppCloseButton';

interface Props extends DialogProps {
  title: string;
  onClose: () => void;
  dialogContent: React.ReactNode;
}

const AppDialog = ({ title, onClose, dialogContent, ...props }: Props) => {
  return (
    <Dialog maxWidth="sm" fullWidth disableScrollLock {...props}>
      <DialogTitle sx={{ paddingBottom: 0 }}>
        {title}
        <AppCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
    </Dialog>
  );
};

export default AppDialog;
