import { z } from 'zod';
import { OpportunitySource } from './OpportunitySource';

export const DraftOpportunity = z.object({
  title: z
    .string({
      required_error: 'Veuillez renseigner le titre',
    })
    .min(1, 'Veuillez renseigner le titre'),
  picture: z.string().url().nullish(),
  publishedAt: z.coerce.date().nullish(),
  source: OpportunitySource.nullish(),
  sourceId: z.string().nullish(),
  link: z.string().nullish(),
  brand: z.string().nullish(),
  model: z.string().nullish(),
  fuel: z.string().nullish(),
  gearbox: z.string().nullish(),
  kilometers: z.number().int().nullish(),
  year: z.coerce.number().int().nullish(),
  price: z.number().nullish(),
  description: z.string().nullish(),
  postalCode: z.string().nullish(),
  rawData: z.record(z.unknown()).nullish(),
  ownerName: z.string().nullish(),
  ownerEmail: z.string().nullish(),
  ownerPhone: z.string().nullish(),
  ownerSourceId: z.string().nullish(),
});

export const Opportunity = z
  .object({
    ...DraftOpportunity.shape,
    id: z.string().uuid(),
    ownerId: z.string().uuid().nullish(),
  })
  .omit({
    ownerName: true,
    ownerEmail: true,
    ownerPhone: true,
    ownerSourceId: true,
  });

export type DraftOpportunity = z.infer<typeof DraftOpportunity>;
export type Opportunity = z.infer<typeof Opportunity>;
