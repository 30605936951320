import {
  ArrowBackIos,
  ArrowForwardIos,
  Close,
  Notifications,
} from '@mui/icons-material';
import { Box, Button, ButtonProps, IconButton } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { TeamOpportunity } from 'shared/schema/TeamOpportunity/TeamOpportunity';
import OpportunityCard from 'src/components/OpportunityBoard/OpportunityCard';

interface Props {
  teamOpportunities: TeamOpportunity[];
  previewButtonProps: ButtonProps;
}

const OpportunitiesPreview = ({
  teamOpportunities,
  previewButtonProps,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClick}
        startIcon={<Notifications />}
        sx={{ marginLeft: 5 }}
        {...previewButtonProps}
      />
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        disableRestoreFocus
        draggable
      >
        <Box>
          <Box
            sx={{
              position: 'absolute',
              top: 5,
              right: 5,
              backgroundColor: 'white',
              borderRadius: 25,
            }}
          >
            <IconButton
              size="small"
              color="default"
              onClick={handleClose}
              sx={{ p: 0 }}
            >
              <Close fontSize="small" />
            </IconButton>
          </Box>
          {teamOpportunities.length > 1 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '50%',
                alignItems: 'center',
                borderRadius: 2,
                position: 'absolute',
                top: 1,
                backgroundColor: 'white',
              }}
            >
              <IconButton
                size="small"
                color="primary"
                onClick={() =>
                  setCurrentIndex(
                    (currentIndex - 1 + teamOpportunities.length) %
                      teamOpportunities.length
                  )
                }
              >
                <ArrowBackIos fontSize="small" />
              </IconButton>
              <Typography component={'span'}>
                {currentIndex + 1} / {teamOpportunities.length}
              </Typography>
              <IconButton
                size="small"
                color="primary"
                onClick={() =>
                  setCurrentIndex((currentIndex + 1) % teamOpportunities.length)
                }
              >
                <ArrowForwardIos fontSize="small" />
              </IconButton>
            </Box>
          )}
          <OpportunityCard opportunity={teamOpportunities[currentIndex]} />
        </Box>
      </Popover>
    </div>
  );
};

export default OpportunitiesPreview;
