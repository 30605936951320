import { z } from 'zod';

export const OpportunityStatus = z.enum([
  'ToContact',
  'InProgress',
  'Meeting',
  'OfferSent',
  'Mandate',
  'Lost',
]);

export type OpportunityStatus = z.infer<typeof OpportunityStatus>;

export const OpportunityStatusList: OpportunityStatus[] =
  OpportunityStatus.options;

export const OpportunityStatusLabel: Record<OpportunityStatus, string> = {
  ToContact: 'À contacter',
  InProgress: 'En cours',
  Meeting: 'RDV',
  OfferSent: 'Offre envoyée',
  Mandate: 'Mandat',
  Lost: 'Perdu',
};
