import {
  Box,
  Button,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { UserToCreate } from 'shared/schema/User/User';
import {
  UserRole,
  UserRoleLabels,
  UserRoleList,
} from 'shared/schema/User/UserRole';
import { useForm } from 'src/hooks/useForm';
import { useFindTeamsQuery } from 'src/services/team.service';

export interface Props {
  onSubmit: (user: UserToCreate) => Promise<void>;
  open: boolean;
  onClose: () => void;
}

function AddUserDialog({ onSubmit, open, onClose }: Props) {
  const { data: teams } = useFindTeamsQuery();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('Vendor');
  const [teamId, setTeamId] = useState<string>();
  const [isTeamUser, setIsTeamUser] = useState(false);

  const { message, validate, messageType } = useForm(UserToCreate, {
    firstName,
    lastName,
    email,
    role,
    teamId,
  });

  const submitUser = async () => {
    await validate(
      async () =>
        await onSubmit({
          firstName,
          lastName,
          email,
          role: role as UserRole,
          teamId,
        })
    );
  };

  return (
    <Box component="form" onSubmit={submitUser}>
      <Dialog open={open} scroll="paper" disableScrollLock fullWidth>
        <DialogTitle>Nouvel utilisateur</DialogTitle>

        <DialogContent>
          <FormControl variant="standard" fullWidth>
            <TextField
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              autoFocus
              label="Prénom"
              type="text"
              fullWidth
              variant="standard"
              margin="normal"
              error={messageType('firstName') === 'error'}
            />
            <FormHelperText error>{message('firstName')}</FormHelperText>
          </FormControl>
          <FormControl variant="standard" fullWidth>
            <TextField
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              label="Nom"
              type="text"
              fullWidth
              variant="standard"
              margin="normal"
              error={messageType('lastName') === 'error'}
            />
            <FormHelperText error>{message('lastName')}</FormHelperText>
          </FormControl>
          <FormControl variant="standard" fullWidth>
            <TextField
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              label="Email"
              type="email"
              fullWidth
              variant="standard"
              margin="normal"
              error={messageType('email') === 'error'}
            />
            <FormHelperText error>{message('email')}</FormHelperText>
          </FormControl>
          <FormControl sx={{ marginTop: 2 }}>
            <RadioGroup row name="row-radio-buttons-group">
              <FormControlLabel
                control={<Radio />}
                label="Utilisateur individuel"
                checked={!isTeamUser}
                onChange={() => {
                  setIsTeamUser(false);
                  setTeamId(undefined);
                  setRole('Vendor');
                }}
              />
              <FormControlLabel
                control={<Radio />}
                label="Membre d'une équipe"
                checked={isTeamUser}
                onChange={() => setIsTeamUser(true)}
              />
            </RadioGroup>
          </FormControl>
          {isTeamUser && (
            <Grid container spacing={2} marginTop={2}>
              <Grid size={{ xs: 12, lg: 6 }}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="select-team-label">Equipe</InputLabel>
                  <Select
                    labelId="select-team-label"
                    value={teamId}
                    onChange={(event) =>
                      setTeamId(event.target.value as string)
                    }
                  >
                    {teams?.map((team) => (
                      <MenuItem key={team.id} value={team.id}>
                        {team.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>{message('teamId')}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, lg: 6 }}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="select-role-label">Rôle</InputLabel>
                  <Select
                    labelId="select-role-label"
                    value={role}
                    onChange={(event) => setRole(event.target.value as string)}
                  >
                    {UserRoleList.filter((role) => role !== 'Admin').map(
                      (role) => (
                        <MenuItem key={role} value={role}>
                          {UserRoleLabels[role]}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  <FormHelperText error>{message('role')}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
          <Button variant="contained" type="submit" onClick={submitUser}>
            Créer l'utilisateur
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddUserDialog;
