import { z } from 'zod';
import { UserPermission, UserPermissionList } from './UserPermission';

export const UserRole = z.enum(['Admin', 'Manager', 'Vendor']);

export type UserRole = z.infer<typeof UserRole>;

export const UserRoleList: UserRole[] = UserRole.options;

export const UserRolePermissions: Record<UserRole, UserPermission[]> = {
  Admin: UserPermissionList,
  Manager: [],
  Vendor: [],
};

export const UserRoleLabels: Record<UserRole, string> = {
  Admin: 'Administrateur',
  Manager: 'Manager',
  Vendor: 'Commercial',
};
