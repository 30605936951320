import { Box, Container } from '@mui/material';
import fp from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FindOpportunityOptions } from 'shared/schema/Opportunity/FindOpportunityOptions';
import { OpportunityStatus } from 'shared/schema/Opportunity/OpportunityStatus';
import FilterMenu from 'src/components/FilterMenu/FilterMenu';
import OpportunityBoard from 'src/components/OpportunityBoard/OpportunityBoard';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';
import { useFindTeamOpportunitiesQuery } from 'src/services/teamOpportunity.service';
import opportunitySlice from 'src/store/reducers/opportunitiesSlice';

const OpportunitiesView = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { findOpportunityOptions } = useAppSelector(
    (state) => state.opportunities
  );

  const { data: opportunities } = useFindTeamOpportunitiesQuery(
    findOpportunityOptions
  );
  const { data: opportunitiesToRemind } = useFindTeamOpportunitiesQuery({
    hasReminder: true,
  });

  useEffect(() => {
    dispatch(
      opportunitySlice.actions.changeFindOptions({
        status: searchParams.get('status') as OpportunityStatus,
        hasReminder: searchParams.get('hasReminder') === 'true',
        hasPhone: searchParams.get('hasPhone') === 'true',
        minPrice: searchParams.get('minPrice')
          ? Number(searchParams.get('minPrice'))
          : undefined,
        maxPrice: searchParams.get('maxPrice')
          ? Number(searchParams.get('maxPrice'))
          : undefined,
        managedBy: searchParams.get('managedBy')?.split(','),
        query: searchParams.get('query'),
        gearbox: searchParams.get('gearbox')?.split(','),
        fuel: searchParams.get('fuel')?.split(','),
      })
    );
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeFilter = (findFilter: Partial<FindOpportunityOptions>) => {
    const filteredParams = fp.omit(
      fp.omitBy(
        {
          ...fp.mapValues(findOpportunityOptions, (value) => value?.toString()),
          ...fp.mapValues(findFilter, (value) => value?.toString()),
        },
        fp.isEmpty
      ),
      ['page', 'perPage']
    );

    const urlSearchParams = new URLSearchParams(
      filteredParams as Record<string, string>
    );

    setSearchParams(urlSearchParams, { replace: true });
  };
  return (
    <>
      <Box
        sx={{
          overflowX: 'auto',
          display: 'flex',
          padding: 2,
          minHeight: 'calc(100vh - 168px)',
        }}
      >
        <Container maxWidth="xl">
          <FilterMenu
            onChangeFilter={changeFilter}
            opportunitiesToRemind={opportunitiesToRemind ?? []}
          />
          <OpportunityBoard opportunities={opportunities ?? []} />
        </Container>
      </Box>
    </>
  );
};

export default OpportunitiesView;
