import { Box, Button, FormHelperText } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { UserPasswordUpdate } from 'shared/schema/User/User';
import AppError from 'src/components/_app/AppError/AppError';
import { useForm } from 'src/hooks/useForm';
import { useUpdateUserPasswordMutation } from 'src/services/user.service';
import { z } from 'zod';

interface Props {
  userId: string;
  onSubmit?: () => void;
}

const AccountPasswordForm = ({ userId, onSubmit }: Props) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const [updatePassword, { isError: isUpdateError }] =
    useUpdateUserPasswordMutation({
      fixedCacheKey: 'shared-signup',
    });

  const Form = z.object({
    ...UserPasswordUpdate.shape,
    newPasswordConfirm: z
      .string({
        required_error: 'Veuillez confirmer votre mot de passe',
      })
      .min(1, 'Veuillez confirmer votre mot de passe.'),
  });

  const FormRefinement = Form.refine(
    ({ newPassword, newPasswordConfirm }) => newPassword === newPasswordConfirm,
    {
      path: ['newPasswordConfirm'],
      message: 'Les mots de passe ne correspondent pas',
    }
  );

  const { message, validate, messageType } = useForm(FormRefinement, {
    oldPassword,
    newPassword,
    newPasswordConfirm,
  });

  const submit = async () => {
    await validate(async () => {
      await updatePassword({
        userId,
        userPasswordUpdate: {
          oldPassword,
          newPassword,
        },
      })
        .unwrap()
        .then(() => {
          onSubmit?.();
        });
    });
  };

  return (
    <Box component="form">
      <TextField
        value={oldPassword}
        onChange={(event) => setOldPassword(event.target.value)}
        autoFocus
        label="Mot de passe actuel"
        type="password"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('oldPassword') === 'error'}
        required
      />
      <FormHelperText error>{message('oldPassword')}</FormHelperText>
      <TextField
        value={newPassword}
        onChange={(event) => setNewPassword(event.target.value)}
        autoFocus
        label="Nouveau mot de passe"
        type="password"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('newPassword') === 'error'}
        required
      />
      <FormHelperText error>{message('newPassword')}</FormHelperText>
      <TextField
        value={newPasswordConfirm}
        onChange={(event) => setNewPasswordConfirm(event.target.value)}
        autoFocus
        label="Confirmation du nouveau mot de passe"
        type="password"
        fullWidth
        margin="normal"
        variant="standard"
        error={messageType('newPasswordConfirm') === 'error'}
        required
      />
      <FormHelperText error>{message('newPasswordConfirm')}</FormHelperText>
      <AppError open={isUpdateError} />
      <Button
        variant="contained"
        onClick={submit}
        sx={{ marginY: '2rem' }}
        fullWidth
      >
        Enregistrer
      </Button>
    </Box>
  );
};

export default AccountPasswordForm;
