import {
  Checklist,
  LocationOn,
  Mail,
  Person,
  Phone,
} from '@mui/icons-material';
import { Icon, Link, Paper, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';
import { TeamOpportunity } from 'shared/schema/TeamOpportunity/TeamOpportunity';
import OpportunitiesPreview from 'src/components/OpportunitiesPreview/OpportunitiesPreview';
import AppWithIcon from 'src/components/_app/AppWithIcon/AppWithIcon';
import { useGetOwnerQuery } from 'src/services/owner.service';
import { useFindTeamOpportunitiesQuery } from 'src/services/teamOpportunity.service';
import { formatPhoneNumber, pluralize } from 'src/utils/stringUtils';

interface Props {
  opportunity: TeamOpportunity;
}

const NotSpecified = () => (
  <Typography variant="caption">
    <i>Non renseigné</i>
  </Typography>
);

const OpportunityOwner = ({ opportunity }: Props) => {
  const { data: owner } = useGetOwnerQuery(opportunity.ownerId ?? skipToken);
  const { data: ownerOpportunities } = useFindTeamOpportunitiesQuery({
    ownerId: opportunity.ownerId,
  });

  const otherOpportunities = useMemo(
    () => (ownerOpportunities ?? []).filter((o) => o.id !== opportunity.id),
    [ownerOpportunities, opportunity.id]
  );

  return (
    <Paper
      sx={{
        padding: 2,
        borderRadius: 2,
        marginTop: 1,
      }}
      elevation={5}
    >
      <AppWithIcon
        icon={<Icon component={LocationOn} fontSize={'small'} color={'info'} />}
        sx={{ marginBottom: 1 }}
      >
        <Typography>{opportunity.postalCode ?? <NotSpecified />}</Typography>
      </AppWithIcon>

      {owner && (
        <>
          <AppWithIcon
            icon={<Icon component={Person} fontSize={'small'} color={'info'} />}
            sx={{ marginBottom: 1 }}
          >
            <Typography>{owner.name ?? <NotSpecified />}</Typography>
          </AppWithIcon>
          <AppWithIcon
            icon={<Icon component={Mail} fontSize={'small'} color={'info'} />}
            sx={{ marginBottom: 1 }}
          >
            {owner.email ? (
              <Link
                href={`mailto:${owner.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {owner.email}
              </Link>
            ) : (
              <NotSpecified />
            )}
          </AppWithIcon>
          <AppWithIcon
            icon={<Icon component={Phone} fontSize={'small'} color={'info'} />}
            sx={{ marginBottom: 1 }}
          >
            {owner.phone ? (
              <Link href={`tel:${owner.phone}`}>
                {formatPhoneNumber(owner.phone)}
              </Link>
            ) : (
              <NotSpecified />
            )}
          </AppWithIcon>
        </>
      )}
      {otherOpportunities.length > 0 && (
        <AppWithIcon
          icon={
            <Icon component={Checklist} fontSize={'small'} color={'info'} />
          }
        >
          <OpportunitiesPreview
            teamOpportunities={otherOpportunities}
            previewButtonProps={{
              children: (
                <Typography variant="caption">
                  {otherOpportunities.length}{' '}
                  {pluralize(otherOpportunities.length)('autre annonce')}
                </Typography>
              ),
            }}
          />
        </AppWithIcon>
      )}
    </Paper>
  );
};

export default OpportunityOwner;
