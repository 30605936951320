import { z } from 'zod';

export const OpportunitySource = z.enum(['LeBonCoin', 'LaCentrale']);

export type OpportunitySource = z.infer<typeof OpportunitySource>;

export const OpportunitySourceList: OpportunitySource[] =
  OpportunitySource.options;

export const OpportunitySourceLabels: Record<OpportunitySource, string> = {
  LeBonCoin: 'leboncoin',
  LaCentrale: 'La Centrale',
};
