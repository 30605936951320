import { ArrowBack } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Brand } from 'shared/constants';
import AccountForgotPasswordForm from 'src/components/Account/AccountForgotPassword/AccountForgotPasswordForm';
import AccountResetPasswordForm from 'src/components/Account/AccountResetPasswordForm/AccountResetPasswordForm';
import AccountSigninForm from 'src/components/Account/AccountSigninForm/AccountSigninForm';
import { H1 } from 'src/components/Styled/Styled';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';
import {
  useForgotPasswordMutation,
  useResetPasswordMutation,
} from 'src/services/account.service';

type ResetPasswordState =
  | 'Loading'
  | 'InitForgot'
  | 'ForgotSubmitted'
  | 'InitReset'
  | 'ResetSubmitted';

const ResetPasswordView = () => {
  useDocumentTitle('Réinitialisez votre mot de passe');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const tokenId = useMemo(() => searchParams.get('tokenId'), [searchParams]);

  const [
    forgotPassword,
    {
      isLoading: isForgotPasswordLoading,
      isSuccess: isForgotPasswordSuccess,
      data: email,
      reset: resetForgotPassword,
    },
  ] = useForgotPasswordMutation({ fixedCacheKey: 'shared-forgot-password' });
  const [
    _, // eslint-disable-line @typescript-eslint/no-unused-vars
    { isLoading: isResetPasswordLoading, isSuccess: isResetPasswordSuccess },
  ] = useResetPasswordMutation({ fixedCacheKey: 'shared-reset-password' });

  const resetPasswordState: ResetPasswordState = useMemo(() => {
    if (isForgotPasswordLoading || isResetPasswordLoading) {
      return 'Loading';
    } else if (isForgotPasswordSuccess) {
      return 'ForgotSubmitted';
    } else if (isResetPasswordSuccess) {
      return 'ResetSubmitted';
    } else if (tokenId) {
      return 'InitReset';
    } else {
      return 'InitForgot';
    }
  }, [
    isForgotPasswordLoading,
    isResetPasswordLoading,
    isForgotPasswordSuccess,
    isResetPasswordSuccess,
    tokenId,
  ]);

  return (
    <Container maxWidth="sm">
      {resetPasswordState === 'Loading' && (
        <Box sx={{ textAlign: 'center', marginY: '10rem' }}>
          <CircularProgress />
        </Box>
      )}
      {resetPasswordState === 'InitForgot' && (
        <>
          <H1>Réinitialisez votre mot de passe</H1>
          <AccountForgotPasswordForm />
        </>
      )}
      {resetPasswordState === 'InitReset' && (
        <>
          <H1>Réinitialisez votre mot de passe</H1>
          <AccountResetPasswordForm tokenId={tokenId!} />
        </>
      )}
      {resetPasswordState === 'ForgotSubmitted' && (
        <Box>
          <H1>Demande enregistrée</H1>
          <Alert severity="success" sx={{ marginY: '2rem' }}>
            <AlertTitle>
              Un mail de réinitialisation vous a été envoyé
            </AlertTitle>
            <Typography sx={{ paddingBottom: '1rem' }}>
              Rendez vous sur votre <b>votre boite mail {email}</b>, vous avez
              dû recevoir un message pour réinitialiser votre mot de passe.
            </Typography>
            <Typography sx={{ paddingBottom: '1rem' }}>
              <b>Vous ne trouvez pas le mail ?</b> <br />
              Vérifiez qu’il ne s’est pas glissé dans vos spams ou
              <Button
                variant="text"
                size="small"
                sx={{ padding: 0 }}
                onClick={() => {
                  if (email) {
                    forgotPassword(email);
                  }
                }}
              >
                renvoyer le mail
              </Button>
            </Typography>
          </Alert>
          <Button
            variant="text"
            startIcon={<ArrowBack />}
            onClick={() => resetForgotPassword()}
          >
            Retour
          </Button>
        </Box>
      )}
      {resetPasswordState === 'ResetSubmitted' && (
        <Box>
          <H1>Le réinitialisation de votre mot de passe est terminée.</H1>
          <Alert severity="success" sx={{ marginY: '2rem' }}>
            <AlertTitle>Connectez-vous</AlertTitle>
            <Typography sx={{ paddingBottom: '1rem' }}>
              Vous pouvez de nouveau profiter de {Brand} en vous connectant avec
              votre email et votre mot de passe.
            </Typography>
          </Alert>
          <AccountSigninForm
            onConnect={() => navigate('/')}
            showSignup={false}
          />
        </Box>
      )}
    </Container>
  );
};

export default ResetPasswordView;
