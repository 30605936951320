import { AuthUser } from 'shared/schema/User/AuthUser';
import { DraftUser } from 'shared/schema/User/User';
import { api } from 'src/services/api.service';

export const accountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    signUp: builder.mutation<DraftUser, DraftUser>({
      query: (draftUser) => ({
        url: 'accounts/sign-up',
        method: 'POST',
        body: draftUser,
      }),
      transformResponse: (baseQueryReturnValue, meta, draftUser) => draftUser,
    }),
    checkEmail: builder.mutation<void, string>({
      query: (tokenId) => ({
        url: `accounts/check-email?tokenId=${tokenId}`,
        method: 'POST',
      }),
    }),
    signIn: builder.mutation<AuthUser, { email: string; password: string }>({
      query: ({ email, password }) => ({
        url: 'accounts/sign-in',
        method: 'POST',
        body: { email, password },
      }),
      transformResponse: (result: any) => AuthUser.parse(result),
      invalidatesTags: ['User'],
    }),
    forgotPassword: builder.mutation<string, string>({
      query: (email) => ({
        url: 'accounts/forgot-password',
        method: 'POST',
        body: { email },
      }),
      transformResponse: (baseQueryReturnValue, meta, email) => email,
    }),
    resetPassword: builder.mutation<
      void,
      { tokenId: string; newPassword: string }
    >({
      query: ({ tokenId, newPassword }) => ({
        url: `accounts/reset-password?tokenId=${tokenId}`,
        method: 'PUT',
        body: { newPassword },
      }),
    }),
    sendPasswordLink: builder.mutation<string, string>({
      query: (email) => ({
        url: 'accounts/password-link',
        method: 'POST',
        body: { email },
      }),
      transformResponse: (baseQueryReturnValue, meta, email) => email,
    }),
  }),
});

export const {
  useSignUpMutation,
  useCheckEmailMutation,
  useSignInMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useSendPasswordLinkMutation,
} = accountApi;
