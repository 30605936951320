import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Container, Tab } from '@mui/material';
import * as React from 'react';
import TeamList from 'src/components/TeamList/TeamList';
import UserList from 'src/components/UserList/UserList';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { useDocumentTitle } from 'src/hooks/useDocumentTitle';
const UsersView = () => {
  useDocumentTitle('Utilisateurs');
  const { isAdmin } = useAuthentication();

  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  if (!isAdmin) {
    return <></>;
  }
  return (
    <Container maxWidth="xl" sx={{ marginTop: '3rem' }}>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Utilisateurs" value="1" />
              <Tab label="Equipes" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <UserList />
          </TabPanel>
          <TabPanel value="2">
            <TeamList />
          </TabPanel>
        </TabContext>
      </Box>
    </Container>
  );
};

export default UsersView;
